var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "bg-black h-auto z-10 h-16",
        attrs: { id: "globalFooter" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "container pt-4 mx-auto gap-3 flex flex-col justify-center items-center text-md sm:text-md md:flex-row md:justify-between md:pr-60",
          },
          [
            _c(
              "a",
              {
                staticClass: "text-white text-center inline-block",
                attrs: { target: "_blank", href: _vm.companyLink },
              },
              [_vm._v("Company")]
            ),
            _c(
              "a",
              {
                staticClass: "text-white text-center inline-block",
                attrs: { target: "_blank", href: _vm.newsLink },
              },
              [_vm._v("News")]
            ),
            _c(
              "a",
              {
                staticClass: "text-white text-center inline-block",
                attrs: { target: "_blank", href: _vm.columnLink },
              },
              [_vm._v("Column")]
            ),
            _c(
              "a",
              {
                staticClass: "text-white text-center inline-block",
                attrs: { target: "_blank", href: _vm.joinLink },
              },
              [_vm._v("Join us")]
            ),
            _c(
              "a",
              {
                staticClass: "text-white text-center inline-block",
                attrs: { target: "_blank", href: "/privacy-policy" },
              },
              [_vm._v("Privacy Policy")]
            ),
            _c(
              "a",
              {
                staticClass: "text-white text-center inline-block",
                attrs: { target: "_blank", href: "/contact" },
              },
              [_vm._v("Contact")]
            ),
          ]
        ),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "container mx-auto col-span-1 text-white text-center sm:text-right md:px-3 lg:px-0",
      },
      [
        _c("p", { staticClass: "w-full text-md py-3" }, [
          _vm._v("©FILMINATION CO., Ltd"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }