var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "header_nav relative col-span-12 border-b-2 h-20",
        class:
          ["manager", "consumer", "provider"].indexOf(_vm.loginUserRole) < 0
            ? "hidden"
            : "",
      },
      [
        _c("div", { staticClass: "top-0 absolute col-span-12 z-m-2" }),
        _vm.loginUserRole == "manager"
          ? _c(
              "ul",
              {
                staticClass:
                  "container mx-auto flex justify-around items-center h-full text-center tracking-wide gap-3 flex-wrap sm:gap-0",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/movies")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/movies" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.movie"))),
                    ]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "hover:text-black relative",
                    class:
                      _vm.currentPath.startsWith("/admin/chs") ||
                      _vm.currentPath.startsWith("/admin/vods") ||
                      _vm.currentPath.startsWith("/admin/admins")
                        ? "active"
                        : "text-brown-grey",
                    on: {
                      mouseover: function ($event) {
                        _vm.modalName = "users"
                      },
                      mouseleave: function ($event) {
                        _vm.modalName = null
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("components.navigation.user"))),
                    ]),
                    _c(
                      "PulldownModal",
                      {
                        attrs: {
                          modalName: "users",
                          currentOpenModalName: _vm.modalName,
                          classList:
                            "origin-top-left absolute w-32 top-0 -ml-2",
                        },
                        on: {
                          closeModal: function ($event) {
                            _vm.modalName = null
                          },
                        },
                      },
                      [
                        _c("ul", { staticClass: "pulldown-language" }, [
                          _c(
                            "li",
                            { staticClass: "uppercase pl-2 py-1" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "hover:text-black",
                                  class: _vm.currentPath.startsWith(
                                    "/admin/chs"
                                  )
                                    ? "active"
                                    : "text-brown-grey",
                                  attrs: { to: "/admin/chs" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("components.navigation.provider")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "uppercase pl-2 py-1" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "hover:text-black",
                                  class: _vm.currentPath.startsWith(
                                    "/admin/vods"
                                  )
                                    ? "active"
                                    : "text-brown-grey",
                                  attrs: { to: "/admin/vods" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("components.navigation.consumer")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "uppercase pl-2 py-1" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "hover:text-black",
                                  class: _vm.currentPath.startsWith(
                                    "/admin/admins"
                                  )
                                    ? "active"
                                    : "text-brown-grey",
                                  attrs: { to: "/admin/admins" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("components.navigation.admins")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "hover:text-black relative",
                    class: _vm.currentPath.startsWith("/admin/agreements")
                      ? "active"
                      : "text-brown-grey",
                    on: {
                      mouseover: function ($event) {
                        _vm.modalName = "agreements"
                      },
                      mouseleave: function ($event) {
                        _vm.modalName = null
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.setting"))),
                    ]),
                    _c(
                      "PulldownModal",
                      {
                        attrs: {
                          modalName: "agreements",
                          currentOpenModalName: _vm.modalName,
                          classList:
                            "origin-top-left absolute w-52 top-0 -ml-2",
                        },
                        on: {
                          closeModal: function ($event) {
                            _vm.modalName = null
                          },
                        },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "pulldown-language" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "hover:text-black",
                                class: _vm.currentPath.startsWith(
                                  "/admin/agreements"
                                )
                                  ? "active"
                                  : "text-brown-grey",
                                attrs: { tag: "li", to: "/admin/agreements" },
                              },
                              [
                                _c(
                                  "li",
                                  { staticClass: "uppercase pl-2 py-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.navigation.agreement"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/admin/tools")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/admin/tools" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.tools"))),
                    ]),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/admin/featurelist")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/admin/featurelists" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(
                        _vm._s(_vm.$t("components.navigation.feature_lists"))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/admin/quote")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/admin/quotes" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.quotes"))),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm.loginUserRole == "provider"
          ? _c(
              "ul",
              {
                staticClass:
                  "container mx-auto flex items-center h-full justify-around text-center",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black cursor-pointer",
                    class: _vm.currentPath.startsWith("/movies")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { tag: "li", to: "/movies" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("components.navigation.product"))),
                    ]),
                  ]
                ),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "hover:text-black cursor-pointer",
                      class: _vm.currentPath.startsWith("/ch/movies/")
                        ? "active"
                        : "text-brown-grey",
                      on: { click: _vm.showCreateModal },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("components.navigation.add"))),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black cursor-pointer",
                    class: _vm.currentPath.startsWith("/ch/quotes")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { tag: "li", to: "/ch/quotes" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("components.navigation.quotes"))),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "text-brown-grey hover:text-black cursor-pointer",
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "/ch/paid_services", target: "_blank" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.navigation.paid_services")
                            )
                          ),
                        ]),
                        _c(
                          "svg",
                          {
                            staticClass: "w-5 h-5 pb-1 inline",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              "stroke-width": "1.5",
                              stroke: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "hover:text-white cursor-pointer rounded-full py-1 px-4 font-bold",
                    class:
                      _vm.currentPath.startsWith("/ch/access_logs") ||
                      _vm.currentPath.startsWith("/ch/getpremium")
                        ? "bg-red-600 text-black"
                        : "text-white bg-red-400",
                    attrs: { tag: "li", to: _vm.premiumLinkPath },
                  },
                  [_c("span", [_vm._v("プレミアムサービス")])]
                ),
              ],
              1
            )
          : _vm.loginUserRole == "consumer"
          ? _c(
              "ul",
              {
                staticClass:
                  "container mx-auto flex items-center h-full justify-between text-center tracking-wide uppercase",
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/movies")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/movies" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.movie"))),
                    ]),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/vod/favorites")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/vod/favorites" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.favorits"))),
                    ]),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "hover:text-black",
                    class: _vm.currentPath.startsWith("/vod/quotes")
                      ? "active"
                      : "text-brown-grey",
                    attrs: { to: "/vod/quotes" },
                  },
                  [
                    _c("span", { staticClass: "uppercase" }, [
                      _vm._v(_vm._s(_vm.$t("components.navigation.quotes"))),
                    ]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "flex justify-end text-black",
                    on: { click: _vm.gotoCart },
                  },
                  [
                    _vm.cartCount > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bg-mango rounded-full h-8 w-8 flex items-center justify-center text-xs font-bold",
                          },
                          [_vm._v(" " + _vm._s(_vm.cartCount) + " ")]
                        )
                      : _vm._e(),
                    _c("img", {
                      staticClass: "w-6 h-6 inline-block",
                      attrs: { src: require("@/assets/image/cart-22.svg") },
                    }),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      [
        _c("NoticeLinkModal", { attrs: { modalName: "createMovie" } }, [
          _c("div", { staticClass: "w-full" }, [
            _c(
              "button",
              {
                staticClass:
                  "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold mb-4",
                on: { click: _vm.showSingleCreate },
              },
              [_c("span", [_vm._v("作品単体登録")])]
            ),
            _c(
              "button",
              {
                staticClass:
                  "px-2 py-2 w-full h-full relative text-center bg-base-yellow font-bold",
                on: { click: _vm.showMultipleCreate },
              },
              [_c("span", [_vm._v("作品一括登録")])]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }