var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c(
      "div",
      { staticClass: "container mx-auto" },
      [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-6 gap-1 sm:grid-cols-12 sm:gap-6 pt-6 pb-3 lg:mx-0",
            attrs: { id: "globalHeader" },
          },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "col-span-4 col-start-2 flex md:col-start-1 lg:col-span-3",
                attrs: { to: "/" },
              },
              [
                _vm.environment === "production"
                  ? _c("img", {
                      staticClass: "w-full flex items-center",
                      attrs: {
                        src: require("../assets/image/filmi_logo_bl_beta.svg"),
                      },
                    })
                  : _vm.environment === "staging"
                  ? _c("img", {
                      staticClass: "w-full flex items-center",
                      attrs: {
                        src: require("../assets/image/filmi_logo_bl_stg_2.svg"),
                      },
                    })
                  : _c("img", {
                      staticClass: "w-full flex items-center",
                      attrs: {
                        src: require("../assets/image/filmi_logo_bl.svg"),
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "hidden md:flex col-span-8 md:col-start-5 items-center justify-between pt-1",
              },
              [
                _vm.$i18n.locale === "ja"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "text-lg flex items-center py-1",
                        attrs: {
                          to: "/about-online-film-market",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" オンラインフィルムマーケットとは ")]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  { staticClass: "text-lg flex items-center py-1" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-full relative",
                        on: {
                          mouseover: function ($event) {
                            return _vm.openModalByName("language")
                          },
                          mouseleave: function ($event) {
                            return _vm.openModalByName()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "w-full h-full" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.pulldownModal.menu.language")
                            )
                          ),
                        ]),
                        _c(
                          "PulldownModal",
                          {
                            attrs: {
                              modalName: "language",
                              currentOpenModalName: _vm.modalName,
                              classList: "absolute w-28 top-0 -ml-2",
                            },
                            on: {
                              closeModal: function ($event) {
                                return _vm.openModalByName()
                              },
                            },
                          },
                          [
                            _c(
                              "ul",
                              { staticClass: "pulldown-language py-2" },
                              [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "col-span-1 text-left text-sm pl-2 py-1 hover:bg-gray-300",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLanguage("en")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.pulldownModal.language.en"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "col-span-1 text-left text-sm pl-2 py-1 hover:bg-gray-300",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLanguage("ja")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.pulldownModal.language.ja"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "button",
                  { staticClass: "text-lg flex items-center py-1" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-full relative",
                        on: {
                          mouseover: function ($event) {
                            return _vm.openModalByName("help")
                          },
                          mouseleave: function ($event) {
                            return _vm.openModalByName()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "w-full h-full" }, [
                          _vm._v(
                            _vm._s(_vm.$t("components.pulldownModal.menu.help"))
                          ),
                        ]),
                        _c(
                          "PulldownModal",
                          {
                            attrs: {
                              modalName: "help",
                              currentOpenModalName: _vm.modalName,
                              classList: "absolute w-48 top-0 -ml-2",
                            },
                            on: {
                              closeModal: function ($event) {
                                return _vm.openModalByName()
                              },
                            },
                          },
                          [
                            _c("ul", { staticClass: "pulldown-help py-2" }, [
                              _vm.displayType === "ch"
                                ? _c(
                                    "li",
                                    {
                                      staticClass:
                                        "col-span-1 text-left text-sm hover:bg-gray-300",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "mt-2 pl-2 block",
                                          attrs: {
                                            to: "/faq/faq-product-regist/",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.pulldownModal.help.guide"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "col-span-1 text-left text-sm hover:bg-gray-300",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "mt-2 pl-2 block",
                                      attrs: {
                                        to:
                                          "/help/" +
                                          _vm.simplifiedLoginUserRole,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.pulldownModal.help.manual"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "col-span-1 text-left text-sm hover:bg-gray-300",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "mt-2 pl-2 block",
                                      attrs: { href: "/faq", target: "_blank" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.pulldownModal.help.faq"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "col-span-1 text-left text-sm hover:bg-gray-300",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "mt-2 pl-2 block",
                                      attrs: {
                                        href: "/words",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.pulldownModal.help.dictionary"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.isLoggedIn
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "p-1 max-w-xs text-center flex items-center border border-black bg-white rounded relative",
                        class: _vm.impersonatedClass,
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full h-6 text-sm bg-white flex items-center relative",
                            class: _vm.impersonatedClass,
                            on: {
                              mouseover: function ($event) {
                                return _vm.openModalByName("profile")
                              },
                              mouseleave: function ($event) {
                                return _vm.openModalByName()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex-auto text-right flex",
                                class: _vm.impersonatedClass,
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "profile_icon w-full py-1 pl-1 align-middle",
                                  },
                                  [_vm._v(_vm._s(_vm.fullName))]
                                ),
                              ]
                            ),
                            _vm.isPremium
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "absolute -top-3 -right-5 text-xxs leading-none rounded-full bg-off-yellow p-1 border border-white shadow",
                                  },
                                  [
                                    _vm.premiumType === "premium_gold"
                                      ? [_vm._v("プレミアムゴールド")]
                                      : [_vm._v("プレミアム")],
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "PulldownModal",
                              {
                                attrs: {
                                  modalName: "profile",
                                  currentOpenModalName: _vm.modalName,
                                  classList:
                                    "pulldown-profile top-0 absolute w-full top-m-5 -ml-4",
                                },
                                on: {
                                  closeModal: function ($event) {
                                    return _vm.openModalByName()
                                  },
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "mt-11 bg-white pl-5" },
                                  [
                                    _vm.loginUser.loginUserRole !== "manager"
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "col-span-1 text-left text-sm mt-2 hover:bg-gray-300",
                                            on: { click: _vm.openProfile },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.pulldownModal.menu.profile"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isImpersonated
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "col-span-1 text-left text-sm mt-2 hover:bg-gray-300",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.$modal.show(
                                                  "updatePasswordModal"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.pulldownModal.menu.user_pass"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "li",
                                      {
                                        staticClass:
                                          "col-span-1 text-left text-sm mt-2 hover:bg-gray-300",
                                        on: { click: _vm.logout },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.pulldownModal.menu.logout"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.isImpersonated
                                      ? _c(
                                          "li",
                                          {
                                            staticClass:
                                              "col-span-1 text-left text-sm mt-2 hover:bg-gray-300",
                                            on: {
                                              click: function ($event) {
                                                return _vm.impersonatedLogout()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.pulldownModal.menu.exit_imprsonate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.impersonatedUserName
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.impersonatedUserName
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "text-lg font-semibold flex items-center text-white bg-brown-grey rounded px-6 py-1",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openLoginModal.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("pages.users.login.login_title")) +
                            " "
                        ),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass:
                  "block sm:hidden fixed rounded-full h-14 w-14 bottom-4 mb-1 left-4 ml-1 text-lg font-semibold bg-off-yellow hover:bg-off-yellow-lighter z-50 shadow-xl",
                on: {
                  click: function ($event) {
                    return _vm.$modal.show("menuModal")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "w-full h-full flex justify-center items-center",
                  },
                  [_vm._v(" Menu ")]
                ),
              ]
            ),
            _c(
              "modal",
              {
                staticClass: "w-10/12",
                attrs: {
                  name: "menuModal",
                  height: "auto",
                  scrollable: true,
                  adaptive: true,
                },
              },
              [
                _c("div", { staticClass: "relative" }, [
                  _c(
                    "h1",
                    {
                      staticClass:
                        "uppercase text-center font-normal text-2xl p-3",
                    },
                    [_vm._v("Menu")]
                  ),
                  _c(
                    "button",
                    { staticClass: "h-5 w-5 absolute m-2 top-2 right-2" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/image/close_icon_gray.svg"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.hide("menuModal")
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "w-full mx-auto", attrs: { tag: "div" } },
                  [
                    _c("div", { staticClass: "w-10/12 mx-auto h-screen" }, [
                      _c(
                        "div",
                        {
                          staticClass: "w-full text-lg items-center pt-4 pb-2",
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "w-full text-left" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.pulldownModal.menu.language"
                                  )
                                )
                              ),
                            ]),
                            _c("ul", { staticClass: "py-2" }, [
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "w-full text-left text-sm p-2 hover:bg-gray-300",
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkModalEn()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.pulldownModal.language.en"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "w-full text-left text-sm p-2 hover:bg-gray-300",
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkModalJp()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.pulldownModal.language.ja"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-full text-lg items-center pt-4 pb-2",
                        },
                        [
                          _c("div", { staticClass: "w-full text-left" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.pulldownModal.menu.help")
                              )
                            ),
                          ]),
                          _c("ul", { staticClass: "py-2" }, [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "w-full text-left text-sm p-2 hover:bg-gray-300",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkModalJp()
                                  },
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/faq/faq-product-regist/" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.pulldownModal.help.guide"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "w-full text-left text-sm p-2 hover:bg-gray-300",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkModalJp()
                                  },
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/help/" + _vm.simplifiedLoginUserRole,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.pulldownModal.help.manual"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "w-full text-left text-sm p-2 hover:bg-gray-300",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkModalJp()
                                  },
                                },
                              },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: "/faq", target: "_blank" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.pulldownModal.help.faq"
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "w-full text-left text-sm p-2 hover:bg-gray-300",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "/words", target: "_blank" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.pulldownModal.help.dictionary"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm.isLoggedIn
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-full text-lg flex flex-start flex-col py-2 my-2",
                              class: _vm.impersonatedClass,
                            },
                            [
                              _c("div", { staticClass: "text-left" }, [
                                _vm._v("My account"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "w-full text-xs",
                                  class: _vm.impersonatedClass,
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-left pl-2",
                                      class: _vm.impersonatedClass,
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "w-full py-1 align-middle",
                                        },
                                        [_vm._v(_vm._s(_vm.fullName))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        modalName: "profile",
                                        currentOpenModalName: _vm.modalName,
                                        classList: "w-full",
                                      },
                                      on: {
                                        closeModal: function ($event) {
                                          return _vm.openModalByName()
                                        },
                                      },
                                    },
                                    [
                                      _c("ul", { staticClass: "py-2" }, [
                                        _vm.loginUser.loginUserRole !==
                                        "manager"
                                          ? _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "col-span-1 text-left text-sm p-2 hover:bg-gray-300",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.linkModalOpenProfile()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.pulldownModal.menu.profile"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isImpersonated
                                          ? _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "col-span-1 text-left text-sm p-2 hover:bg-gray-300",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.linkModalUpdatePasswordModal()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.pulldownModal.menu.user_pass"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "col-span-1 text-left text-sm p-2 hover:bg-gray-300",
                                            on: { click: _vm.logout },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.pulldownModal.menu.logout"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.isImpersonated
                                          ? _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "col-span-1 text-left text-sm p-2 hover:bg-gray-300",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.impersonatedLogout()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.pulldownModal.menu.exit_imprsonate"
                                                      )
                                                    )
                                                ),
                                                _vm.impersonatedUserName
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.impersonatedUserName
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "text-lg font-semibold flex items-center text-white bg-brown-grey rounded",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "w-full h-full px-6 py-2",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openLoginModal.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Login ")]
                              ),
                            ]
                          ),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c("LoginModal", { on: { clickLostPass: _vm.openLostPasswordModal } }),
        _c("LostPasswordModal"),
        _c("UpdatePasswordModal"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }